:root {
  --header-background-main: #075f29;
  --header-background-secondary: #1d1d20;
  --header-background-secondary-hover: #37373a;
  --title-font-color: #fff;
  --nav-font-color: #fff;
  --link-hover: #e5e6eb;
  --footer-background: rgb(63, 63, 73);
  --divider-color: rgb(192, 192, 202);
  --font-dark: rgb(218, 216, 226);
  --home-article-background: rgb(43, 131, 48);
  --program-background: rgb(35, 35, 39);
  --hover-background-team: #09692e;
  --hover-background-sponsor: #27272b;
}

.App {
  text-align: center;
  max-width: 100vw;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*main*/

.sticky {
  position: fixed;
  top: 0;
}

html {
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

body{
  margin: 0px;
  padding: 0px;
  height: auto;
}

a:visited {
  color: white;
}

.page-wrap {
  min-height:100vh;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: var(--link-hover) !important;
}

.content-wrapper {
  min-height: 76vh;
  height: auto;
  margin: 2vh 2vw 2vh 2vw; 
}

header {
  grid-column-start: 1;
  grid-column-end: span 3;
  margin: 0px;
  background: var(--header-background-main);
  display: flex;
  flex-direction: column;
  width: 100vw;
}



#title{
  grid-row-start: 1;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;
}

#title img{
  height: 80px;
  margin-left: 1rem;
  display:inline-flex;
  
}

#title a{
  font-size: 40px;
  width:fit-content;
  height: fit-content;
  color: var(--title-font-color);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: auto 0;
}

.team-header p {
  color: var(--title-font-color);
  font-size: 24px;
  margin-top: auto;
  margin-bottom: auto;
  padding-right:2vw;
}

.team-header {
  display:inline-flex;
  align-self:right;
}

.header-container {
  display:flex;
  justify-content: space-between;
}

.header-nav-container{
  height: 3rem;
  display: flex;
  justify-content: space-evenly;
  overflow: visible;
  display:inline-flex;
  flex-grow:2;
  align-items:flex-start;
}

.header-space-container {
  display: inline-flex;
  flex-grow: 5;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  background-color: var(--header-background-secondary);
  width:100vw;
  align-items:flex-start;
}

.dropdown-button {
  margin: 0;
  border: none;
  outline: none;
  background-color: inherit;
  display: none;
}

.dropdown-content {
  display: none;
  flex-flow: column wrap;
  position: relative;
  z-index: 1;
  width: 100%;
  background: var(--header-background-secondary-hover);
  padding: 0;
  margin: 0;
}

.dropdown-content a {
  display: inline-flex !important;
  width: 100% !important;
  flex-flow: column wrap;   
  min-height: fit-content;
}

.dropdown-link-container {
  display: flex !important;
  width: 100% !important;
  flex-flow: column wrap;
  overflow: visible;
  position: relative;
  z-index:1;
  min-height: fit-content;
}

.dropdown-nested-container {
  background: var(--header-background-secondary-hover);
  display: none;
  position:relative;
  flex-flow: column wrap !important;
  overflow: visible;
  width:87%; /* Small fix for nested, TODO*/
  z-index: 1;
  left:83%;
} 

.nested-link-container {
 display: flex !important;
 width: 100% !important;
 height: 3rem;
 flex-flow: column wrap;
 position:absolute;
 overflow: visible;
 background: var(--header-background-secondary-hover);
 color: black !important;
}

.nested-link-container:nth-child(1) {
  bottom: 100%
}

.nested-link-container:nth-child(3) {
  bottom: 200%
}



.dropdown-link-container:hover .dropdown-nested-container {
  display: inline-flex !important;
  flex-flow: wrap row !important;
  
  
}

.navContainer:hover .dropdown-content {
    display: flex;
}



.navContainer{
  background: var(--header-background-secondary);
  width: 0px;
  display: inline-flex;
  flex-flow: row wrap;
  overflow: visible;
  flex-grow: 1;
  min-height: fit-content;
  min-width: 100px;

}

.navContainer:hover{
  background: var(--header-background-secondary-hover);
}

.navContainer a{
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: var(--nav-font-color);
  height: 3rem;
  min-height: fit-content;
  min-width: fit-content;
}


/* footer */

footer { 
  background: var(--footer-background);
  display:flex;
  align-items: flex-start;
  justify-content: space-between;
  height: fit-content;
  max-width: 100vw;
  flex-flow: row wrap;
  padding: 5px 0;
}

.logo-wrap {
  display:flex;
  justify-content: center;
  margin: auto 0;
  min-width: fit-content;
  max-width: 50vw;
  overflow: hidden;
}

.logo-footer {
  display: inline-flex;
  width: calc(100vw/7);
  max-width: fit-content;
  height: 3em;
  margin: auto 0px auto 10px;
}

footer img, .logo-wrap a {
  height: inherit; 
}

#frc-logo-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  filter: none;
}

#facebook-logo-footer, #instagram-logo-footer, #twitter-logo-footer, #youtube-logo-footer, #github-logo-footer {
  filter: invert();
}

.footer-info {
  color: var(--font-dark);
  height:100%;
  width:auto;
  display:flex;
  align-items: center;
  justify-content: center;
  margin: auto 1rem;
  font-size: 16px;
}

/* HOME */

#team-image {
  width:100vw;
  height: auto;
  display: inline-flex;
  margin-bottom: 8rem;
  background-color: var(--header-background-secondary);
}

#team-image img {
  width: 100vw;
  height: auto;
  max-height: 100vh;
}

.Home {
  min-height: 100vh;
}

#home-quote-container {
  background-color: var(--header-background-main);
  display: flex;
  height:fit-content;
  width: fit-content;
  padding: 3rem;
  border: black 1px solid;
  margin: auto;
  color: var(--title-font-color);
}

.home-article {
  height: fit-content;
  display: flex;
  width: 80%;
  margin: 7rem auto 3rem auto;
  text-align: left;
  font-size: 20px;
  flex-flow: row wrap;
}

.home-article p {
  display: inline-flex;
}

#history-title {
  display: flex;
  font-size: 40px;
  justify-content: center;
  width: 100%;
}

#program-overview {
  background: var(--program-background);
  width: 100%;
  height: fit-content;
  display: flex;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

#program-content-container {
  width: 80%;
  height: 80%;
  display: flex;
  margin: auto;
  color: var(--title-font-color);
  flex-flow: row wrap;
}

#program-content-container p, #program-content-container ul {
  font-size: 20px;
  width: 100%;
  height: fit-content;
  text-align: left;
  margin-top: 2em;
}

#program-content-title {
  font-size: 40px;
  width: 100%;
  height: fit-content;
}

.title-container {
  height: fit-content;
  width: fit-content;
}

#program-content-title h {
  border-bottom: var(--divider-color) 2px solid;
  width: fit-content;
  padding: 0 2rem 4px 2rem;
}

.home-article h {
  width: fit-content;
  height: fit-content;
  padding: 0 2rem 4px 2rem;
  display: flex;
  justify-content: center;
  border-bottom: var(--divider-color) 2px solid;
}

.home-quote {
  display: flex;
  width: 100%;
  height: 50%;
  font-size: 30px;
  text-align: center;
  margin: auto;
}

.home-quote h {
  width: 100%;
  height: fit-content;
  margin: auto;
}

#home-wrapper {
  display:flex;
  width:100vw;
  min-height:inherit;
  padding-bottom: 16rem;
  justify-content: center;
  flex-flow: column wrap;
}

#sponsor-teams-splash {
  display: flex;
  width:100%;
  height: min-content;
  flex-flow: row wrap;
  justify-content: center;
}

#team-more {
  width: 100%;
  height: min-content;
  font-size: 40px;
  padding-top: 3em;
}

#team-more h {
  width: min-content;
  padding: 0 2rem;
  border-bottom: var(--divider-color) 3px solid;
}

.sponsor-button {
  display: flex;
  width: 15rem;
  height: 6rem;
  background-color: var(--program-background);
  color: var(--title-font-color);
  text-align: center;
  justify-content: center;
  position: relative;
  top: 3em;
}

.sponsor-button:hover {
  background-color: var(--hover-background-sponsor);
}

.sponsor-button a {
  height: 100%;
  width: 100%;
  font-size: 30px;
  display: flex;
  justify-content: center;
}

.sponsor-button a span {
  display: flex;
  height: min-content;
  margin: auto;
}

.team-links {
  display: flex;
  width: 90%;
  padding: 0 5%;
  padding-top: 5em;
  height: 10rem;
  justify-content: space-around;
}

.FRC-button, .FTC-button, .FLL-button {
  display: flex;
  width: 25vw;
  max-width: 15em;
  height: 17vw;
  max-height: 10em;
  background-color: var(--header-background-main);
  color: var(--title-font-color);
  justify-content: center;
  text-align: center;
}

.FRC-button:hover, .FTC-button:hover, .FLL-button:hover {
  background-color: var(--hover-background-team);
}

.FRC-button a, .FTC-button a, .FLL-button a {
  height: 100%;
  width: 100%;
  font-size: 30px;
  display: flex;
}

.FRC-button a span, .FTC-button a span, .FLL-button a span {
  height: min-content;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.FRC-button, .FLL-button {
  position: relative;
  top: 50%;
}

#program-content-container ul li {
  padding-bottom: 8px;
}

/* INDEX */

article{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#general{
  display:inline-grid;
  grid-column:2;
}

#general-item {
  display:grid;
}

.index-item {
  display:inline-grid;
  margin-left:5rem;
  margin-right:5rem
}

.article-title {
  display: flex;
  width: auto;
  height:auto;
  margin-top: auto;
  font-size: 3rem;
  display: inline-grid;
}

.FLL-img-wrap, .FRC-img-wrap {
  grid-row: 1;
  grid-column: 2;
}

.FLL-img-wrap img, .FTC-img-wrap img, .FRC-img-wrap img {
  height:34rem;
}



.index-img-wrap, .FTC-img-wrap, .FLL-img-wrap, .FRC-img-wrap {
  height:auto;
  display: inline-grid;
  grid-row: 1;
  overflow:hidden;
}

.FTC-img-wrap, .FLL-img-wrap, .FRC-img-wrap {
  width:65vw;
}

.index-img-wrap {
  width: 50vw;
}

.FTC-item {
  display:grid;
}

.pContainer{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 2;
  display: flex;
  justify-content: center;
}

.pContainer p{
  margin-bottom: auto;
  margin-top:0%;
  padding-right: 8%;
  padding-left:8%;
}

.aContainer{
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.aContainer a{
  margin-top: auto;
  margin-bottom: auto;
}

.awards-list{
  text-align: left;
}

/* FRC */

.about-program-frc img {
  width: 80%;
  height: auto;
  margin: 0 10vw;
}

.about-program-frc p {
  margin-left: 2rem;
  margin-right: 2rem;
}

.frc-container .article-container {
  text-align: start !important;
  width:90%;
  margin: auto;
  margin-top:4rem;
}

.frc-container {
  margin-bottom: 4rem;
}

.divider hr {
  background-color: var(--divider-color);
  height: 2px;
  border: none;
  margin-top:2rem;
  margin-bottom:2rem;
}

/* PHOTOS */
.photo-spread {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: auto;
  width: 100vw;
  padding-bottom: 15rem;
}


.photo-entry {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  height: 30rem;
  overflow: hidden;
  border: white 1px solid;
}

.photo-entry img {
  width: auto;
  height: 30rem;
}
/* Training Modules page*/

.training-link {
  color: #1c1ce6 !important;
}

.training-link:hover {
  color: #5880ee !important;
}

.training-table{
  table-layout: fixed;
  width: 100%;
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
}

.training-table li {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  word-wrap: break-word;
  padding-left: 5px;
  padding-right: 5px;
}

.train-img {
  width: 50%;
  height: auto;
}

.train-category {
  padding: 5px;
}

/* Robots */

.high-res-robot {
  width: 62%;
}

/* Outreach Image */

.outreach-img {
  width: 62%;
}